@import "Styles/variables.css";

body {
    height: 100%;
    margin: 0;
}
html {
    min-height: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    position: relative;
}
/*ROOT*/

.fix-margin {
    margin-top: 5em;
}
/* Navbar */
.navbar {
    /* background-color: #ed8000; */
    margin-top: 0px !important;
}

.navbar a {
    color: black;
}
.navbar svg {
    color: #bf381a;
    font-size: 2em;
}

.logo {
    width: 8em;
    height: 2em;
}
.cursor {
    cursor: pointer;
}

.nav-order-icon {
    margin: auto;
}

.nav-badge {
    margin-top: 0.2em;
    height: 40%;
    background-color: #ea640d !important;
    border-radius: 100%;
}
/* Navbar */

/*Swiper*/
.swiper {
    width: 90%;
    height: 100%;
    /* background: linear-gradient(
        90deg,
        rgba(34, 34, 34, 0.16) 0%,
        rgba(0, 0, 0, 0) 8%,
        rgba(0, 0, 0, 0) 92%,
        rgba(34, 34, 34, 0.16) 100%
    ); */
    border-radius: 20px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-color: unset !important;
    margin-bottom: 20px;
}

.Category-Swipper-img {
    display: block;
    margin: auto;
    width: 5em;
    height: 4em;
    border-radius: 100%;
}
/*Swiper*/

/*Cards*/
.card-body {
    padding-top: 0.5em !important;
}

.Product-Container {
    /* margin-bottom: 110px; */
}

.card {
    border: none;
    background-color: unset;
}
.cartext {
    font-size: x-small;
}
.card-button {
    border-radius: 25px;
    color: black;
    border: none;
    outline: 1px solid #ea640d;
}

.card-button:hover {
    background-color: #bf381a;
    color: white;
}

.card-button:focus {
    outline: none !important;
    box-shadow: none;
}
.product-card-img {
    height: 170px;
    border-radius: 20px 20px 0 0;
}
.product-cart-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Products-Card-Button-Container {
    display: flex;
    flex-direction: column;
}

.Products-Card-Body {
    border-radius: 0 0 20px 20px;
}

@media only screen and (max-width: 560px) {
    .product-card-col {
        padding-right: 0.3rem !important;
        padding-left: 0.3rem !important;
    }
}

/*Cards*/

/*Modal*/
.Modal-Fixed-Nav {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
}
.no-gutters {
    margin: 1.2em;
    border-bottom: 1px solid lightgray;
}

.react-responsive-modal-modal {
    border-radius: 15px;
    max-width: 1200px !important;
    max-height: 70% !important;
    min-width: 70% !important;
    /* min-height: 50% !important; */
    padding: 0px !important;
}

.Modal-Nav {
    display: flex;
    justify-content: space-between;
}
.Modal-Nav-Right-Container {
    display: flex;
}
.Modal-ico {
    width: 1.5em;
    height: 1.5em;
}

.Modal-Close-Button {
    display: flex;
    border: none;
    outline: none;
    background-color: unset;
}
.Modal-Close-Button svg {
    width: 1.5em;
    height: 1.5em;
}
.Modal-Amount-Button {
    max-width: 50px;
    max-height: 30px;
    min-width: 20px;
    min-height: 15px;
    padding: 0;
    display: flex;
    justify-content: center;
}
.modal-card-img {
    max-width: 400px;
    max-height: 200px;
}

.Modal-Fixed-Foot {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: white;
}

.Modal-foot {
    display: flex;
    justify-content: center;
}

.No-Cart {
    padding: 2em;
    display: flex;
    flex-direction: column;
}

.No-Cart-Icon {
    margin: auto;
    font-size: xx-large;
}

.Order-Delete-Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    margin: auto;
}
.modalConfirmButton {
    background-color: #ea640d;
    color: white;
    border: none;
    border-radius: 25px;
}
.modalConfirmButton:hover {
    background-color: #bf381a;
    color: white;
}

.modalConfirmButton:focus {
    outline: none !important;
    box-shadow: none;
}
.modalConfirmButton:active {
    background-color: #bf381a;
    outline: none !important;
    box-shadow: none;
}
.modalConfirmButton:active {
    outline: none !important;
    box-shadow: none;
}
/*Modal*/

/*Footer*/

.bi {
    vertical-align: -0.125em;
    fill: currentColor;
}

.Footer-Container {
    max-height: 150px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

/*Footer*/

/*Popover*/
.popover {
    background-color: unset;
    border: none;
}

.popover-body {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0;
    padding-bottom: 0;
}

.Popover-Container {
    display: flex;
    position: sticky;
    bottom: 1.5em;
    justify-content: end;
    margin-right: 1em;
    margin-bottom: 7em;
}

.Popoever-list {
    padding: 1em;
    list-style: none;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 1em;
    width: 100%;
}
.Popoever-Icon {
    margin-right: 0.5em;
}

.Popover-Button {
    border-radius: 15px;
    border: none;
    background-color: #ea640d;
    padding: 0.5em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Popover-Button:active .Popover-Button {
    background-color: #bf381a !important;
}

.Popover-Button:focus .Popover-Button {
    background-color: #bf381a !important;
}

.Popover-Button:hover {
    background-color: #bf381a !important;
}

.Popover-Button:focus .Popover-Button {
    outline: none !important;
    box-shadow: none;
    background-color: #bf381a !important;
}

/*Popover*/

/*Product Details*/

.Product-Detail-Container {
    margin-top: 6em;
    margin-bottom: 7em;
}

.Product-Detail-img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 300px;
    display: flex;
    margin: auto;
    align-items: center;
    border-radius: 15px;
}

.Product-Details-Button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1.5em;
}

.Product-Details-Up {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Product-Details-Swipper-img {
    border-radius: 20px 20px 0 0;
}

.Product-Details-Swipper-cart {
    border-radius: 0 0 20px 20px;
}
/*Product Details*/

/*Waiter*/
.Table-Icon {
    margin: auto;
    font-size: 10em;
    color: black;
}

.waiter-button-container {
    display: flex;
    flex-direction: column;
}

.waiter-button-container > button {
    background-color: #ea640d;
    border: none;
    border-radius: 15px;
}

.waiter-card-body {
    border-radius: 15px;
}
.Waiter-Container {
    margin-bottom: 7em;
}
