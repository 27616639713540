:root {
    --c-body-bg: #f4f4f4;
    --c-font-primary: #2c2c2c;
    --c-font-secondary: rgba(98, 98, 98, 1);
    --c-font-blue: #23395d;
    --c-font-passive: rgba(98, 98, 98, 0.6);
    --c-font-disabled: rgba(137, 135, 135, 0.6);
    --c-btn: #6993ff;
    --c-heart-primary: #fe251b;
    --c-star: #f9d71c;
    --c-white: #fff;
    --c-black: #000;
    --c-white-8: rgba(255, 255, 255, 0.8);
    --c-box-bg-0: #ebf1a1;
    --c-box-bg-1: #ebf2d1;
    --c-box-bg-2: #fff0f0;
    --c-box-bg-3: #d9edf9;
    --c-box-bg-4: #fac8cb;
    --c-box-bg-5: #93b7cd;
    --c-box-bg-6: #f6a3a8;
    --c-box-bg-7: #d4e0ee;
    --c-box-bg-8: #fdefe3;
    --c-box-bg-9: #c6e8da;
    --c-banner-bg: rgba(217, 237, 249, 1);
    --c-bg-primary: #7367f0;
    --c-hv-primary: #ddd;
    --c-bg-dark-0: #161d31;
    --c-bg-dark-1: #283046;
    --c-bg-dark-2: #4c5c88;
    --c-bg-dark-3: #c0bbff;
    --c-bg-dark-4: #9086ff;
    --c-font-dark: #d0d2d6;

    --c-palet-1: #bf381a;
    --c-palet-2: #ea640d;
    --c-palet-3: #f5a64a;
    --c-palet-4: #fbc57b;
    --c-palet-5: #fcdca3;
}
